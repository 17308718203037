import React, { useEffect, useState } from "react";
import ListingTable from "../../component/ListingTable";
// import { MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import Services from "../../../../api/services";
import Filter from "./filter";
interface Column {
  id:
    | "serviceName"
    | "noOfServices"
    | "serviceAmount"
    | "tips"
    | "refund"
    | "total"
    | "paymentDate"
    | "payPeriod";
  label: string;
  minWidth?: number;
}

const columns: readonly Column[] = [
  { id: "serviceName", label: "service Name", minWidth: 100 },
  { id: "noOfServices", label: "No of services", minWidth: 40 },
  { id: "serviceAmount", label: "Service Amount($)", minWidth: 50 },
  { id: "tips", label: "Tips($)", minWidth: 70 },
  { id: "refund", label: "Refund($)", minWidth: 30 },
  { id: "total", label: "Total($)", minWidth: 50 },
  { id: "paymentDate", label: "Expected Payment Date", minWidth: 170 },
  { id: "payPeriod", label: "Pay Period", minWidth: 130 },
];

interface Data {
  id: number;
  serviceName: string;
  noOfServices: string;
  serviceAmount: number;
  tips: number;
  refund: string;
  total: number;
  paymentDate: string;
  payPeriod: string;
}

function createData(
  id: number,
  serviceName: string,
  noOfServices: string,
  serviceAmount: number,
  tips: number,
  refund: string,
  total: number,
  paymentDate: string,
  payPeriod: string
): Data {
  return {
    id,
    serviceName,
    noOfServices,
    serviceAmount,
    tips,
    refund,
    total,
    paymentDate,
    payPeriod,
  };
}

type Service = {
  service_date_time: Date;
  // service_activated: Date;
  service_amount: string;
  service_name: string;
  tip: string;
  adjustment_amount: string;
};

type ChunkStatistics = {
  service_name: string;
  no_of_services: number;
  total_service_amount: string;
  total_refund_adjustment: number;
  total_tips: number;
  total: number;
  pay_period: string;
  expected_payment_date: string;
};

const Earnings: React.FC = () => {
  const [earningData, setEarningData] = useState<ChunkStatistics[]>([]);
  const [totalEarning, setTotalEarning] = useState(0);
  const [totalTips, setTotalTips] = useState(0);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  const getEarningData = async (sortBy: any = "all") => {
    try {
      const tokenString = localStorage.getItem("access_token");
      const formatDate = (date: Date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = date
          .toLocaleString("en-US", { month: "short" })
          .toLowerCase();
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };

      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        let queryParam = "all";
        if (sortBy[0] !== null && sortBy[1] !== null) {
          const startDate = new Date(
            sortBy[0].getTime() - sortBy[0].getTimezoneOffset() * 60000
          );
          const endDate = new Date(
            sortBy[1].getTime() - sortBy[1].getTimezoneOffset() * 60000
          );

          // Ensure the start and end dates are correctly formatted
          const startISO = formatDate(startDate);
          const endISO = formatDate(endDate);

          queryParam = `${startISO},${endISO}`;
        }

        const response = await Services.provider.getEarningData(
          queryParam,
          options
        );

        if (response.data.data.length > 0) {
          // Convert service_activated and service_date_time to Date object and sort data
          const services: Service[] = response.data.data
            .map((service: any) => ({
              ...service,
              // service_activated: new Date(service.service_activated),
              service_date_time: new Date(
                service.service_date_time.split(" ")[0]
              ),
            }))
            .sort(
              (a: Service, b: Service) =>
                a.service_date_time.getTime() - b.service_date_time.getTime()
            );

          // Define the start date (earliest service date)
          const startDate = services[0].service_date_time;

          // Initialize the dictionary to hold 14-day chunks
          const chunks: { [key: number]: Service[] } = {};

          // Assign services to 14-day chunks based on service_date_time
          services.forEach((service: Service) => {
            const daysDiff = Math.floor(
              (service.service_date_time.getTime() - startDate.getTime()) /
                (1000 * 60 * 60 * 24)
            );
            const chunkIndex = Math.floor(daysDiff / 14);

            if (!chunks[chunkIndex]) {
              chunks[chunkIndex] = [];
            }

            chunks[chunkIndex].push(service);
          });

          let tipTillNow = 0;
          let serviceAmountTillNow = 0;

          // Calculate statistics for each chunk
          const chunkStatistics: ChunkStatistics[] = Object.values(
            chunks
          ).flatMap((chunk: Service[]) => {
            const servicesByName = chunk.reduce((acc, service) => {
              if (!acc[service.service_name]) {
                acc[service.service_name] = [];
              }
              acc[service.service_name].push(service);
              return acc;
            }, {} as { [key: string]: Service[] });

            return Object.entries(servicesByName).map(
              ([service_name, services]) => {
                const no_of_services = services.length;

                const total_service_amount = services
                  .reduce((total, service) => {
                    const serviceAmountAfterDeduction =
                      parseFloat(service.service_amount) * 0.8;
                    const refundAdjustment = parseFloat(
                      service.adjustment_amount
                    );
                    return (
                      total + (serviceAmountAfterDeduction - refundAdjustment)
                    );
                  }, 0)
                  .toFixed(2);

                serviceAmountTillNow += parseFloat(total_service_amount);
                const total_tips = services.reduce(
                  (total, service) => total + parseFloat(service.tip),
                  0
                );

                tipTillNow += total_tips;
                const total_refund_adjustment = services.reduce(
                  (total, service) =>
                    total + parseFloat(service.adjustment_amount),
                  0
                );
                const total =
                  parseFloat(total_service_amount) +
                  total_tips;

                const firstServiceDate = services[0].service_date_time;
                const pay_period_start = firstServiceDate;
                const pay_period_end = new Date(
                  pay_period_start.getTime() + 13 * 24 * 60 * 60 * 1000
                ); // 14 days chunk

                const expected_payment_date = new Date(
                  pay_period_end.getTime() + 3 * 24 * 60 * 60 * 1000
                ); // + 3 days

                return {
                  service_name,
                  no_of_services,
                  total_service_amount,
                  total_tips,
                  total_refund_adjustment,
                  total,
                  pay_period: `${formatDate(pay_period_start)} to ${formatDate(
                    pay_period_end
                  )}`,
                  expected_payment_date: formatDate(expected_payment_date),
                };
              }
            );
          });

          setTotalEarning(serviceAmountTillNow);
          setTotalTips(tipTillNow);
          setEarningData(chunkStatistics);
        } else {
          setEarningData([]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getEarningData(dateRange);
  }, [dateRange]);

  const rows = earningData.map((item: any, index: number) => {
    const rowData = {
      id: index,
      noOfService: item.no_of_services,
      serviceName: item.service_name,
      serviceAmt: item.total_service_amount,
      tip: item.total_tips,
      adjustmentAmount: item.total_refund_adjustment,
      totalAmount: item.total,
      expectedPaymentDate: item.expected_payment_date,
      paymentPeriod: item.pay_period,
    };

    return createData(
      rowData.id,
      rowData.serviceName,
      rowData.noOfService,
      rowData.serviceAmt,
      rowData.tip,
      `${
        rowData.adjustmentAmount > 0
          ? `-${rowData.adjustmentAmount}`
          : rowData.adjustmentAmount
      }`,
      rowData.totalAmount.toFixed(2),
      rowData.expectedPaymentDate,
      rowData.paymentPeriod
    );
  });

  return (
    <Box sx={{ py: 2 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
        spacing={2}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: "24px",
            "@media(max-width: 768px)": {
              fontSize: "18px",
            },
          }}
        >
          Earnings
        </Typography>
        <Box
          sx={{
            marginLeft: "auto",
            zIndex: "10",
            pr: 1,
            "& input": {
              width: "100%",
              p: "5px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              outline: "none",
            },
            "& span": {
              marginRight: "2px",
              fontSize: "16px",
            },
          }}
        >
          <span>Pay period:</span>{" "}
          <Filter setDateRange={setDateRange} dateRange={dateRange} />
        </Box>
      </Stack>

      <ListingTable columns={columns} rows={rows} />

      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">
          Total earning till date: ${totalEarning.toFixed(2)}
        </Typography>

        <Typography variant="h6">
          Total tips till date: ${totalTips.toFixed(2)}
        </Typography>
      </Box>
    </Box>
  );
};

export default Earnings;

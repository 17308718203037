import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import Input from "../../../../component/input";
import Swal from "sweetalert2";
import Services from "../../../../api/services";

interface providersAgreementProps {
  id: number;
  termsErrorMsg: string;
  terms_conditions: boolean;
  name: string;
  date: any;
  allow_background_check: number;
}

interface agreementProps {
  handleClose: (value: boolean) => void;
}

const ProvidersAgreement: React.FC<agreementProps> = ({ handleClose }) => {
  const [errors, setErrors] = useState<Partial<providersAgreementProps>>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState<providersAgreementProps>({
    id: 0,
    termsErrorMsg: "",
    terms_conditions: false,
    name: "",
    date: new Date().toLocaleDateString(),
    allow_background_check: 1,
  });

  const validateForm = () => {
    const newErrors: Partial<providersAgreementProps> = {};

    if (!formData.terms_conditions) {
      newErrors.termsErrorMsg = "Field is required";
    }

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${event.target.name}ErrorMsg`]: "",
    }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitted(true);
      const data = {
        terms_conditions: formData.terms_conditions,
        name: formData.name,
        date: new Date().toLocaleDateString(),
      };

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response =
            await Services.provider.updateAgreementDetailsService(
              data,
              options
            );
          if (response.status === 200) {
            Swal.fire({
              title: "Added!",
              text: "Service provider agreement has been added.",
              icon: "success",
            });
            handleClose(false);
          }
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.errors}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
  };

  const fetchAgreement = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await Services.provider.getAgreementDetailsService(
          options
        );

        setFormData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchAgreement();
  }, []);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            my: 2,
            height: "240px",
            overflow: "auto",
            "& .MuiTypography-root": {
              marginBottom: "10px",
            },
          }}
        >
          <Typography component="p">
            <b>Helping Hands Service Provider Contract</b>
          </Typography>
          <Typography component="p">
            <b>Agreement</b>
          </Typography>
          <Typography component="p">
            This Service Provider Agreement (&quot;Agreement&quot;) is entered
            into by and between 15620767 Canada Inc., operating under the name
            &quot;Helping Hands&quot; (&quot;Helping Hands&quot;), and the
            undersigned service provider (&quot;Service Provider&quot;) under
            the following terms and conditions:
          </Typography>
          <Typography component="p">
            <b>Recitals</b>
          </Typography>
          <Typography component="p">
            WHEREAS, the Company operates as an online platform connecting
            customers seeking various services with skilled service providers;
          </Typography>
          <Typography component="p">
            WHEREAS, the Service Provider possesses the necessary skills,
            qualifications, and tools to provide the services listed on the
            Company's platform;
          </Typography>
          <Typography component="p">
            NOW, THEREFORE, in consideration of the mutual covenants and
            promises contained herein, the parties hereto agree as follows:
          </Typography>
          <Typography component="p">
            <b>1. Service Quality and Customer Satisfaction</b>
          </Typography>
          <Typography component="p">
            The Service Provider acknowledges and agrees to uphold high
            standards of service quality and customer satisfaction in all
            interactions and service deliveries. The Company shall not be held
            responsible for any dissatisfaction or disputes arising from the
            quality of services rendered by the Service Provider.
          </Typography>
          <Typography component="p">
            <b>2. Payment</b>
          </Typography>
          <Typography component="p">
            The Company shall compensate the Service Provider on a weekly basis
            for services rendered as per the agreed-upon pricing structure.
            Payment shall be remitted to the Service Provider's designated
            account via the agreed-upon payment method.
            <br />
            A flat commission rate of 20% unless discussed otherwise,
            <br />
            will be deducted from each transaction.
          </Typography>
          <Typography component="p">
            {" "}
            <b>3. Tools and Liability</b>
          </Typography>
          <Typography component="p">
            The Service Provider shall supply and maintain their own tools
            necessary for the provision of services. The Company assumes no
            liability for any damage, injury, or loss resulting from faulty
            tools, equipment malfunctions, or any wear and tear of tools
            utilized by the Service Provider.
          </Typography>
          <Typography component="p">
            <b>4. Reimbursement and Poor Service Quality</b>
          </Typography>
          <Typography component="p">
            The Service Provider acknowledges that reimbursement for services
            due to poor service quality, as requested by the customer, will be
            entitled to one warning, subsequent poor service (service
          </Typography>
          <Box>
            <Typography component="p">
              for which customer requests refund) shall result in deduction in
              pay based on total refund amount for such services. The Service
              Provider shall bear the responsibility for ensuring the
              satisfactory execution of their services.
            </Typography>
            <Typography component="p">
              <b>5. Code of Conduct and Termination</b>
            </Typography>
            <Typography component="p">
              The Service Provider shall adhere to a professional code of
              conduct, refraining from engaging in physical violence, sexual
              harassment, verbal abuse, or any damage to property. Violation of
              this code shall lead to immediate termination of this Contract and
              may result in legal actions.
            </Typography>
            <Typography component="p">
              <b>6. Communication and Business Transactions</b>
            </Typography>
            <Typography component="p">
              The Service Provider agrees not to independently initiate customer
              contact or engage in any business transactions without the prior
              knowledge and consent of the Company. All customer interactions
              shall be conducted through the Company's platform.
            </Typography>
            <Typography component="p">
              <b>7. Customer Complaints</b>
            </Typography>
            <Typography component="p">
              Any customer complaints regarding the services provided by the
              Service Provider shall be promptly communicated to the Company.
              The Company shall take appropriate actions based on the nature of
              the complaint.
            </Typography>
            <Typography component="p">
              <b>8. Unbiased Reviews</b>
            </Typography>
            <Typography component="p">
              The Service Provider undertakes to provide unbiased and accurate
              reviews of customers' experiences, reflecting the actual quality
              of service rendered.
            </Typography>
            <Typography component="p">
              <b>9. Solicitation of Tips and Extra Monetary Gain</b>
            </Typography>
            <Typography component="p">
              The Service Provider is expressly prohibited from soliciting tips
              or seeking additional monetary compensation beyond the agreed-upon
              service charges.
            </Typography>
            <Typography component="p">
              <b>10. Service Charges and Changes</b>
            </Typography>
            <Typography component="p">
              The Company reserves the right to revise service charges based on
              market conditions or after consultations with the Service
              Provider. Such changes shall be communicated to the Service
              Provider in advance.
            </Typography>
            <Typography component="p">
              <b>11. Employer Benefits</b>
            </Typography>
            <Typography component="p">
              The Service Provider acknowledges that they are an independent
              contractor and not an employee of the Company. As such, the
              Company does not provide health care, pension plans, retirement
              benefits, dental or vision coverage, RRSP, or any other employer
              benefits.
            </Typography>
          </Box>
          <Box>
            <Typography component="p">
              <b>12. Taxation</b>
            </Typography>
            <Typography component="p">
              The Service Provider is solely responsible for the accurate
              reporting and payment of all taxes applicable to the income earned
              under this Contract, in compliance with relevant tax laws and
              regulations.
            </Typography>
            <Typography component="p">
              <b>13. Availability</b>
            </Typography>
            <Typography component="p">
              The Service Provider shall communicate their availability for the
              upcoming week in advance. Changes to availability must be
              communicated no later than 24 hours before the scheduled service,
              except in cases of illness or emergencies.
            </Typography>
            <Typography component="p">
              <b>14. No-Show Policy</b>
            </Typography>
            <Typography component="p">
              Failure to honor a scheduled service appointment without prior
              notice shall result in the Service Provider's limited visibility
              from the Company's platform for a duration of one week.
            </Typography>
            <Typography component="p">
              <b>15. Police Check</b>
            </Typography>
            <Typography component="p">
              ●   Businesses registered in Saskatchewan with multiple employees
              must handle
              <br />
              background checks, liability insurance, and WCB of their own
              employees.
              <br />
              ●   Individuals without business registration will undergo a
              background check at the
              <br />
              expense of Helping Hands.
              <br />
              ●   Non-business registered Service Providers must opt for WCB,
              with annual fees covered
              <br />
              by the respective Service Provider.
            </Typography>
            <Typography component="p">&nbsp;</Typography>
            <Typography component="p">
              <b>Entire Agreement</b>
            </Typography>
            <Typography component="p">
              This Contract contains the entire agreement between the parties
              and supersedes any prior understandings, representations, or
              agreements.
            </Typography>
            <Typography component="p">
              <b>Governing Law</b>
            </Typography>
            <Typography component="p">
              This Contract shall be governed by and construed in accordance
              with the laws of [Country/State]. Any disputes arising out of this
              Contract shall be subject to the exclusive jurisdiction of the
              courts of [Country/State].
            </Typography>
            <Typography component="p">
              Consent:
              <br />
              Consent to use service provider photo and service photos along
              with service price to show on website
            </Typography>
            <Typography component="p">
              IN WITNESS WHEREOF, the parties hereto have executed this Service
              Provider Contract as of the Effective Date.
            </Typography>
          </Box>
          <Typography component="p">
            <b>
              Carefully acknowledged by 15620767 Canada Inc. (Helping Hands)
            </b>
          </Typography>
        </Box>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="terms_conditions"
                checked={formData.terms_conditions}
                onChange={handleCheckboxChange}
              />
            }
            label="I have carefully read the terms & conditions"
          />

          {errors.termsErrorMsg && (
            <Typography component="p" color="error">
              {errors.termsErrorMsg}
            </Typography>
          )}
        </FormGroup>

        <Stack
          spacing={2}
          justifyContent={"space-between"}
          direction={"row"}
          sx={{ mb: 2 }}
        >
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Name:</FormLabel>
            <Input
              type="text"
              name="name"
              placeholder="First Name"
              value={formData.name}
              onChange={handleInputChange}
            />
            {errors.name && (
              <Typography component="p" color="error">
                {errors.name}
              </Typography>
            )}
          </FormGroup>

          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Date:</FormLabel>
            <Input type="text" name="date" value={formData.date} readOnly />
          </FormGroup>
        </Stack>

        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          sx={{ mt: 2 }}
        >
          {formData.id !== 0 &&
          (formData.allow_background_check === 1 ||
            formData.allow_background_check === 2) ? (
            <Typography variant="h6" sx={{ color: "red" }}>
              Note: Documents is under review yet
            </Typography>
          ) : (
            <Button
              type="submit"
              variant="contained"
              disabled={
                (formData.id !== 0 &&
                  (formData.allow_background_check === 1 ||
                    formData.allow_background_check === 2)) ||
                isSubmitted
              }
            >
              {isSubmitted ? (
                <span style={{ color: "#fff" }}>Submitting...</span>
              ) : (
                "Submit"
              )}
            </Button>
          )}
        </Stack>
      </form>
    </Box>
  );
};

export default ProvidersAgreement;
